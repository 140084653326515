.Directions {
    font-family: Cinzel;
}

.Directions > h2 {
    margin-top: 10vh;
}

.Directions > .section {
    display: flex;
    justify-content: center;
}

.Directions > .section > .text-box {
    font-family: Cinzel;
    width: 50vw;
    padding: 5%;
    padding-top: 0;
    padding-bottom: 0;
}

.Directions > .section > .text-box > p {
    font-size: calc(10px + 1.25vmin);
    font-weight: normal;
    text-align: left;
}

.Directions > .section > .text-box > ul > li {
    font-size: calc(10px + 1.25vmin);
    font-weight: normal;
    text-align: left;
}


@media screen and (max-width: 990px) {

    .Directions>.section {
        flex-direction: column;
    }

    .Directions>.section>.text-box {
        width: 90%;
        order: 1;
    }

}