.Event {
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.Event>h2 {
    font-family: Cinzel;
    font-weight: normal;
    font-size: 4rem;
    margin-top: none;
    margin-left: 48px;
    margin-right: 48px;
}

.Event>p {
    font-family: Cinzel;
    font-weight: normal;
    font-size: 3rem;
    margin: 1vh 48px;
}

.hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(113, 12, 76, 1), rgba(0, 0, 0, 0));
}

@media screen and (max-width: 1135px) {

    .Event>h2 {
        font-size: 5.6vw;
    }

    .Event>p {
        font-size: 4vw;
    }

}