body {
  margin: 0;
  font-family: Raleway;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: #A8477D; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #A8477D; /* Gecko Browsers */
}

a {
  color: #A8477D;
}

@font-face {
  font-family: Raleway;
  src: url('./fonts//raleway-medium.ttf');
}

@font-face {
  font-family: Raleway;
  src: url('./fonts//raleway-bold.ttf');
  font-weight: bold;
}


@font-face {
  font-family: Raleway;
  src: url('./fonts//raleway-light.ttf');
  font-weight: lighter;
}


@font-face {
  font-family: Raleway;
  src: url('./fonts//raleway-regular.ttf');
  font-weight: normal;
}


@font-face {
  font-family: Dancing Script;
  src: url('./fonts//DancingScript-SemiBold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Cinzel;
  src: url('./fonts//Cinzel-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Cinzel;
  src: url('./fonts//Cinzel-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts//Montserrat-Regular.ttf');
  font-weight: normal;
}

