.Venue {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.Venue>.section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Venue>.section>.text-box {
    font-family: Cinzel;
    width: 50vw;
    padding: 5%;
    padding-bottom: 0;
}

.Venue>.section>.text-box>p {
    font-weight: normal;
}

.Venue>.section>.photo-box {
    width: 50vw;
    padding: 5%;
    padding-bottom: 0;
}

.Venue>.section>.photo-box>iframe {
    width: 90%;
    height: 31.6vw;
}

@media screen and (max-width: 990px) {


    .Venue>.section {
        flex-direction: column;
    }


    .Venue>.section>.text-box {
        width: 90%;
    }

    .Venue>.section>.photo-box {
        width: 90%;
    }

    .Venue>.section>.photo-box>iframe {
        width: 90%;
        height: 70vw;
    }

}