
.badge {
    position: absolute;
    left: 5%;
    top: 5%;
    height: 69vh; /* 749px orig */
}


.landing-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.landing-page>.title-box {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-page>.title-box>.left-wreath {
    width: 20%;
    height: 80%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    order: 1;
}

.landing-page>.title-box>.right-wreath {
    width: 20%;
    height: 80%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: scaleX(-1);
    order: 3;
}

.landing-page>.title-box>p {
    font-family: Dancing Script;
    font-size: 6.3vw; /*112px orig*/
    order: 2;
}

.landing-page>.main-image {
    width: 100%;
    height: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 84%;
    background-position-x: 89%;
}

.landing-page>.main-image>.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
}


@media screen and (max-width: 1250px) {
    .badge { 
        height: 60vw; 
        left: 0;
        top: 0;
    }
}

@media screen and (max-width: 500px) {

    .landing-page>.main-image {
        height: 60%;
        background-size: cover;
        background-position-y: 84%;
        background-position-x: 89%;
    }

    .landing-page>.title-box {
        height: 40%;
        flex-wrap: wrap;
    }

    .landing-page>.title-box>.left-wreath {
        width: 50%;
        height: 50%;
        order: 2;
    }

    .landing-page>.title-box>.right-wreath {
        width: 50%;
        height: 50%;
        order: 3;
    }

    .landing-page>.title-box>p {
        margin: 3% 0 0 0;
        height: 47%;
        width: 100%;
        font-size: 10vmax;
        order: 1;
    }
}