.App {
  text-align: center;
  background-color: #FFF6FC;
  width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #710c4c;
  width: 100vw;
  height: 7vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: normal;
  font-size: 1rem;
}

.end-banner {
  margin: 10vh 0;
  width: 100vw;
  position: relative;
}

.end-banner>img {
  position: relative;
  width: 40vw;
}

.end-banner>.hr {
  position: absolute;
  top: 50%;
  width: 100vw;
}

.urgent-message {
  position: absolute;
  padding: 5px;
  z-index: 1000;
  background-color: #fad201;
  text-align: left;
}