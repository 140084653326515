.RSVP {
    width: 100vw;
    font-family: Cinzel;
}

.RSVP > h2 {
    margin-top: 10vh;
}

.RSVP > p {
    font-weight: normal;
}

.RSVP > a {
    font-weight: normal;
    text-decoration: none;
}

